<template>
  <div class="about">
    <el-button size="mini" @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
      <el-table  :data="tableData">
        <el-table-column prop="date" label="姓名" width="140">
        </el-table-column>
        <el-table-column prop="name" label="手机" width="120">
        </el-table-column>
        <el-table-column prop="address" label="备注"> </el-table-column>
      </el-table>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="50%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
          <el-upload
              class="avatar-uploader"
              :action="UpURL"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :http-request="httpRequest"
              :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>

    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import {getIMG,getUQIMGurl} from "@/api/common"

export default {
  name: 'about',
  data(){
    return{
      UpURL:'',
      imageUrl:'',
      drawer:false,
      tableData:[],
    }
  },
  created() {
    // this.tabsLogin()
  },
  methods:{
    tabsLogin() {
      // getCategory('').then((res) => {
      //   console.log(res)
      // });
    },
    hQUrl() {
      getUQIMGurl().then((res)=>{
        console.log('获取URL',res)
        if(res.code==200){
          this.UpURL=res.data

        }else {
          return  false
        }
      })
    },
    add(){
      this.drawer=true
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    httpRequest(options) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append("file", options.file)
        console.log(this.UpURL)
        getIMG(formData,this.UpURL).then((res)=>{
          resolve(res)
        }).catch((err)=>{
          reject(err)
        })
      })
    },

    beforeAvatarUpload(file) {

      const isJPG = file.type === 'image/jpeg'||
                    file.type === "image/png" ||
                    file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 4
      this.hQUrl()
      let isURL=getUQIMGurl()
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG、 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 4MB!')
      }
      return isJPG && isLt2M
    },
  }
}
</script>
<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1!important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>